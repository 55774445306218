import React from 'react';
import { MessageOutlined } from '@ant-design/icons';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import useI18n from '../utils/useI18n';
import Link from './Link';

const RelatedPosts = ({ posts }) => {
  const { t } = useI18n();
  const data = useStaticQuery(graphql`
    query RelatedArticlesQuery {
      Article: allArticle {
        nodes {
          id
          Article {
            id
            slug
            titulo
            comments
          }
          featuredImg {
            childImageSharp {
              fluid(maxWidth: 70, maxHeight: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  // const articles = shuffle(data.Article.nodes).slice(0, 2);
  const articles = data.Article.nodes.filter((item) => {
    return posts.includes(item.Article.id);
  });
  return articles.map(({ Article, featuredImg }) => {
    return (
      <div
        className="c-articleMini o-media o-media--small c-articleMini--no-border"
        key={Article.id}
      >
        <div className="o-media__img c-articleMini__img">
          {featuredImg && (
            <Img fluid={featuredImg.childImageSharp.fluid} alt={Article.titulo} fadeIn={false} />
          )}
        </div>
        <div className="o-media__body">
          <h4 className="c-articleMini__title">
            <Link to={`${t('url.blog')}/${Article.id}-${Article.slug}/`}>{Article.titulo}</Link>
          </h4>
          <div className="c-articleMini__meta">
            <span className="c-articleMini__comments">
              <MessageOutlined /> {t('commentsTotal', { count: Article.comments }, false)}
            </span>
          </div>
        </div>
        <Link to={`${t('url.blog')}/${Article.id}-${Article.slug}/`} className="c-blockLink" />
      </div>
    );
  });
};

export default RelatedPosts;

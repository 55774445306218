import React from 'react';
import loadable from '@loadable/component';
import dayjs from 'dayjs';
import get from 'lodash.get';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/seo/SEO';
import ContactButton from '../components/ContactButton';
import useI18n from '../utils/useI18n';
import Share from '../components/Share';
import RelatedPost from '../components/RelatedPosts';
import Comments from '../components/Comments';
import Content from '../components/Content';
import '../styles/components/_components.post.scss';
import useSiteMetadata from '../utils/useSiteMetadata';
import Link from '../components/Link';

loadable(() => import('dayjs/locale/es'));

const Post = ({ pageContext: { switchLang }, data: { record }, location }) => {
  dayjs.locale(get(switchLang, 'lang', 'en'));
  const {
    Article,
    featuredImg,
    Category,
    Tag,
    Related,
    childHtmlRehype: { htmlAst: post },
  } = record;
  const { t } = useI18n();
  const { siteUrl } = useSiteMetadata();

  const schemaArticle = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${siteUrl}${location.pathname}`,
    },
    headline: Article.titulo,
    image: {
      '@type': 'ImageObject',
      url: `${siteUrl}${get(featuredImg, 'childImageSharp.fixed.src')}`,
    },
    datePublished: `${dayjs(Article.created).format()}`,
    dateModified: `${dayjs(Article.modified).format()}`,
    author: {
      '@type': 'Person',
      name: 'Linda Molina',
    },
    publisher: {
      '@type': 'Organization',
      name: 'Mexican Timeshare Solutions',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.timesharescam.com/icons/icon-512x512.png',
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: 5,
        ratingCount: 1120,
      },
    },
    /*review: {
      '@type': 'Review',
      name: Article.titulo,
      rating: {
        '@type': 'Rating',
        ratingValue: `${parseFloat(Article.rating_value || 4.5)}`,
        ratingCount: `${parseInt(Article.rating_count || 3)}`,
      },
      author: {
        '@type': 'Person',
        name: 'Linda Molina',
      },
    },*/
  };

  return (
    <Layout switchLang={switchLang}>
      <SEO
        title={Article.titulo}
        description={Article.description}
        keywords={Article.keywords}
        image={featuredImg ? featuredImg.childImageSharp.fixed : null}
        pathname={location.pathname}
        breadCrumbs={[
          { name: 'Blog', url: `${siteUrl}/blog/` },
          {
            name: get(Category, 'nombre'),
            url: `${siteUrl}/blog${t('url.category')}/${get(Category, 'slug')}/`,
          },
          { name: Article.titulo, url: `${siteUrl}${location.pathname}` },
        ]}
        jsonLD={[schemaArticle]}
      />
      <article className="o-wrapper c-post u-margin-top-large">
        <div className="u-10/12@desktop u-push-1/12@desktop u-align-center">
          <h1 className="c-post__title">{Article.titulo}</h1>
          <span className="c-post__meta">
            {Category && (
              <>
                <Link to={`${t('url.blog')}${t('url.category')}/${get(Category, 'slug')}/`}>
                  {get(Category, 'nombre')}
                </Link>
                {' | '}
              </>
            )}
            {[
              dayjs(Article.created).format('DD MMM YYYY'),
              t('commentsTotal', { count: Article.comments }, false),
            ].join(' | ')}
          </span>
        </div>
        {/*
        <Img
          className="c-post__img"
          fluid={get(featuredImg, 'childImageSharp.fluid')}
          fadeIn={false}
          alt={Article.titulo}
        />
        */}
        <div className="u-10/12@desktop u-push-1/12@desktop">
          <Content htmlAst={post} />
          <div className="c-post__tags">
            <span className="o-footnote">TAGS:</span>
            {Tag.map((item) => (
              <Link
                key={item.id}
                className="c-post__tag"
                to={`${t('url.blog')}${t('url.tag')}/${get(item, 'slug')}/`}
              >
                {item.nombre}
              </Link>
            ))}
          </div>
        </div>
      </article>
      <section className="c-section c-section--white c-section--tiny">
        <div className="c-section__content o-wrapper">
          <p className="o-callout u-margin-bottom-tiny">{t('article.post')}</p>
          <Share url={`${location.origin}${t('url.blog')}/${Article.id}-${Article.slug}/`} />
          {Array.isArray(Related) && (
            <div className="u-4/12@desktop u-push-4/12@desktop u-6/12@tablet u-push-3/12@tablet u-margin-top">
              <strong className="u-h3 u-margin-bottom">{t('article.alsoLike')}</strong>
              <RelatedPost posts={Related.map((item) => item.id)} />
            </div>
          )}
        </div>
      </section>
      <Comments
        id={Article.id}
        type="Article"
        total={Article.comments}
        sendButtonText={t('article.comment.button')}
      />
      <ContactButton section />
    </Layout>
  );
};

export const query = graphql`
  query ArticleQuery($id: String!) {
    record: article(id: { eq: $id }) {
      Article {
        id
        autor
        created
        description
        slug
        slug_es_mx
        slug_en_us
        titulo
        keywords
        contenido
        rating_count
        rating_value
        comments
      }
      childHtmlRehype {
        htmlAst
      }
      Category {
        id
        nombre
        slug
      }
      Tag {
        id
        nombre
        slug
      }
      Related {
        id
      }
      featuredImg {
        childImageSharp {
          fluid(maxWidth: 720, quality: 60) {
            ...GatsbyImageSharpFluid
          }
          fixed(quality: 60) {
            src
            width
            height
          }
        }
      }
    }
  }
`;

export default Post;
